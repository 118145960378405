import React from "react";
import profilePhoto from "./richard.png";
import resume from "./resume.pdf";

export default function HomePage() {
  return (
    <div className="flex-wrapper fade-in-drop fade-in-drop-active">
      <div className="container">
        <section className="HomePage-text">
          <h1 className="HomePage-text-title">Hey! I'm Richard Marquez</h1>
          <div>
            <p>
              I’m a Front end developer, Vue.js user, React developer,
              TypeScript typer, youtube video watcher, semi-annual youtube video
              poster, rnb music listener, bubble tea drinker, peanut avoider,
              and most importantly a fifth year Computer Science student at
              Toronto Metropolitan University. I'm looking for 2024 full time
              positions that start in the spring/summer 2024 term!
            </p>
            <p>
              I'm currently seeking fulltime work as a SWE. I previously
              interned at <span className="HomePage-bold">Coursera</span> as a
              Frontend developer,{" "}
              <span className="HomePage-bold">Shopify </span>as an software
              developer on the analytics team, and Global Creative Technology as
              a Software Engineer. My go-to technologies are currently{" "}
              <span className="HomePage-bold">React</span>, or{" "}
              <span className="HomePage-bold">Vue</span> (with Quasar).
            </p>
          </div>
          <p>
            Besides work and school, I’m the president for the TMU{" "}
            <a href="https://tmucscu.com/">Computer Science Course Union</a>,
            was previously a developer for{" "}
            <a href="https://ruhacks.com/">RUHacks</a>, and founder of the{" "}
            <a href="https://developers.google.com/community/gdsc">
              Google Developer Student Club
            </a>{" "}
            chapter at TMU. If you're coming from @gcm.130, the design project
            is still under progress. You can view my{" "}
            <a href="https://github.com/richardmarquez2001">GitHub</a>,{" "}
            <a href="https://www.linkedin.com/in/richard-marquez-2001/">
              LinkedIn
            </a>
            , <a href="https://devpost.com/richardmarquez2001">Devpost</a>, or
            take a look at my{" "}
            <a target="_blank" rel="noreferrer noopener" href={resume}>
              resume
            </a>
            .
          </p>
          <p>
            If you want to shoot me an email, feel free to{" "}
            <a href="mailto:richard.marquez.2001@gmail.com">contact me here.</a>
          </p>
        </section>

        <section className="HomePage-imageWrapper">
          <div>
            <img
              className={"HomePage-imageContent"}
              src={profilePhoto}
              alt="a portrait jpg of richard marquez"
            />
          </div>

          <div className="HomePage-imageWrapper-caption">
            ~ me at the brooklyn bridge ~
          </div>
        </section>
      </div>
    </div>
  );
}
