import React from "react";
import HomePage from "./sections/HomePage";
import { Helmet } from "react-helmet";
import "./css/App.scss";

export default function App() {
  return (
    <div className="App">
      <Helmet>
        <title>richard marquez</title>
        <link rel="icon" type="image/svg" href="toronto.svg" sizes="16x16" />
        <meta
          name="description"
          content="This is Richard Marquez's personal website, built with React!"
        />
        <meta name="theme-color" content="#8e2fed" />
      </Helmet>
      <HomePage />
    </div>
  );
}
